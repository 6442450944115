import { put, takeLatest } from 'redux-saga/effects';
import customToast from '../../components/alert/CustomToast';
import { deleteApi, getApi, postApi, putApi } from '../../services';
import { requestHandler } from '../../services/handler';
import { DELETE_CATEGORY, GET_CATEGORY, GET_SUBCATEGORY_FOR_CATEGORY, SAVE_CATEGORY, UPDATE_CATEGORY } from '../actionTypes';
import { getAllCategory, setCategory, setSubcategoriesForCategory } from './action';
import { DELETED_SUCCESSFULLY, SAVED_SUCCESSFULLY, UPDATED_SUCCESSFULLY } from '../../constant';
import { requestFinished, requestStarted } from '../requests/action';

const INVENTORY_URL = process.env.REACT_APP_INVENTORY_URL;

function* saveCategory(action) {
  const { payload } = action;

  const cb = () => {
    customToast(`${payload.name} ${SAVED_SUCCESSFULLY}`, 'success');
    return getAllCategory();
  };

  const handleError = (err) => {
    const { errors } = err;
    return errors.map((data) => customToast(data.message, 'error'));
  };

  yield requestHandler(postApi.bind(this, `${INVENTORY_URL}/category`, payload), cb, null, null, handleError);
}

function* fetchCategory(action) {
  const { type } = action;
  yield put(requestStarted({ name: type }));

  const cb = () => {
    return put(requestFinished({ name: type }));
  };

  yield requestHandler(getApi.bind(this, `${INVENTORY_URL}/category`), setCategory, cb, null);
}

function* updateCategory(action) {
  const {
    payload: { id, data }
  } = action;

  const onSuccess = () => {
    return customToast(UPDATED_SUCCESSFULLY, 'success');
  };

  const onError = (err) => {
    const { errors } = err;
    return errors.map((message) => customToast(message, 'error'));
  };

  yield requestHandler(putApi.bind(this, `${INVENTORY_URL}/category/${id}`, data), getAllCategory, onSuccess, null, onError);
}

function* deleteCategory(action) {
  const { payload: categoryId } = action;

  const onSuccess = () => {
    return customToast(DELETED_SUCCESSFULLY, 'success');
  };

  const onError = (err) => {
    const { errors } = err;
    return errors.map((message) => customToast(message, 'error'));
  };

  yield requestHandler(deleteApi.bind(this, `${INVENTORY_URL}/category/${categoryId}`), getAllCategory, onSuccess, null, onError);
}

function* getSubcategoryForCategory(action) {
  const { payload: categoryId } = action;
  yield requestHandler(getApi.bind(this, `${INVENTORY_URL}/category/${categoryId}/subcategories`), setSubcategoriesForCategory, null, null);
}

export function* WatcherCategoryApp() {
  yield takeLatest(SAVE_CATEGORY, saveCategory);
  yield takeLatest(GET_CATEGORY, fetchCategory);
  yield takeLatest(DELETE_CATEGORY, deleteCategory);
  yield takeLatest(GET_SUBCATEGORY_FOR_CATEGORY, getSubcategoryForCategory);
  yield takeLatest(UPDATE_CATEGORY, updateCategory);
}
