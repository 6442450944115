import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Container, Row, Col, Card, CardBody, Button, Input, Label } from 'reactstrap';
import { generatePath, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Active, AddCategory, Disabled, DO_YOU_WANT_TO_DELETE, NO, YES } from '../../../constant';
import CustomDataTables from '../../../components/table/CustomDataTables';
import internalRoutes from '../../../route/internalRoutes';
import CategoryModal from './categoryModal';
import { useDispatch } from 'react-redux';
import { deleteCategory, getAllCategory, resetSubcategoriesForCategory, saveCategory, updateCategory } from '../../../redux/category/action';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import Alerts from '../../../components/alert/CustomAlerts';
import { useTranslation } from 'react-i18next';
import { resetMinicategoriesForSubcategory } from '../../../redux/subcategory/action';
import { CATEGORY_NAME_IS_EMPTY } from './constants';
import { getRequestStatus } from '../../../redux/requests/helper';
import { GET_CATEGORY } from '../../../redux/actionTypes';
import { decode } from 'html-entities';

const ListCategory = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const { allCategory } = useSelector((state) => state.categories);
  const { loading } = useSelector((state) => getRequestStatus(state.requests, GET_CATEGORY));
  const toggleCategoryModal = () => setOpenCategoryModal(!openCategoryModal);

  useEffect(() => {
    if (isEmpty(allCategory)) {
      dispatch(getAllCategory());
    }
  }, []);

  const handleSaveCategory = () => {
    if (categoryName === '') {
      return toast.error(translate(CATEGORY_NAME_IS_EMPTY));
    }

    const data = { name: categoryName, description: categoryDescription };
    dispatch(saveCategory(data));
    toggleCategoryModal();
    setCategoryName('');
  };

  const handleUpdateCategory = (id, data) => {
    if (isEmpty(data)) return;

    dispatch(updateCategory(id, data));
  };

  const handleDeleteCategory = (id) => {
    const category = allCategory.find((cat) => cat.categoryId === id);
    Alerts(
      `${DO_YOU_WANT_TO_DELETE} "${category.name}"?`,
      null,
      'warning',
      {
        confirmButtonText: translate(YES),
        denyButtonText: translate(NO),
        showConfirmButton: true,
        showDenyButton: true
      },
      null,
      () => dispatch(deleteCategory(id))
    );
  };

  const handleRowClick = (row) => {
    dispatch(resetSubcategoriesForCategory());
    dispatch(resetMinicategoriesForSubcategory());
    navigate(generatePath(internalRoutes.GOTO_CATEGORY_DETAILS, { catId: row.categoryId }));
  };

  const columns = [
    {
      name: 'Name',
      selector: (row) => decode(row.name),
      sortable: true
    },
    {
      name: 'Status',
      selector: (row) => row.isActive,
      cell: (row) => {
        return (
          <Button color={row.isActive ? 'success' : 'danger'} size="xs">
            {row.isActive ? Active : Disabled}
          </Button>
        );
      }
    },
    {
      name: 'Featured',
      selector: (row) => row.showInStore,
      cell: (row) => (
        <div className="checkbox checkbox-primary" onClick={() => handleUpdateCategory(row.categoryId, { showInStore: !row.showInStore })}>
          <Input id="checkbox-primary-1" type="checkbox" size={8} checked={row.showInStore} onChange={() => {}} />
          <Label for="checkbox-primary">{}</Label>
        </div>
      )
    },
    {
      name: 'Action',
      selector: (row) => row.action,
      cell: (row) => <i className="icofont icofont-ui-delete" onClick={() => handleDeleteCategory(row.categoryId)} />
    }
  ];

  return (
    <Fragment>
      <Breadcrumb parent="ecommerce" title="all category" />
      <Container fluid={true}>
        <CategoryModal
          title={AddCategory}
          show={openCategoryModal}
          toggle={() => toggleCategoryModal()}
          onSave={() => handleSaveCategory()}
          categoryName={categoryName}
          categoryDescription={categoryDescription}
          handleCategoryName={setCategoryName}
          handleCategoryDescription={setCategoryDescription}
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Button style={{ marginBottom: '10px' }} color="primary" onClick={() => toggleCategoryModal()}>
                  {'Add Category'}
                </Button>
                {!isEmpty(allCategory) && <CustomDataTables columns={columns} data={allCategory} onRowClick={(row, event) => handleRowClick(row, event)} isLoading={loading} />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ListCategory;
