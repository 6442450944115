import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import _, { isEmpty } from 'lodash';
import Breadcrumb from '../../../layout/breadcrumb';
import { Container, Row, Col, Card, CardBody, Button, Input, Label } from 'reactstrap';
import { decode } from 'html-entities';
import { Action, Active, AddMinicategory, AddSubcategory, ADD_SOME_DESCRIPTION_FOR, Description, DO_YOU_WANT_TO_DELETE, Name, NO, Status, YES } from '../../../constant';
// import imageExample from '../../../assets/images/ecommerce/banner.jpg';
import CustomDataTables from '../../../components/table/CustomDataTables';
import { getAllCategory, getSubcategoriesForCategory } from '../../../redux/category/action';
import CategoryModal from './categoryModal';
import { toast } from 'react-toastify';
import { deleteSubcategory, getMinicategoriesForSubcategory, saveSubcategory, updateSubcategory } from '../../../redux/subcategory/action';
import Alerts from '../../../components/alert/CustomAlerts';
import {
  CLICK_TO_SHOW_MINICATEGORIES,
  MINICATEGORIES,
  MINICATEGORY_NAME,
  MINICATEGORY_NAME_IS_EMPTY,
  SUBCATEGORIES,
  SUBCATEGORY_NAME,
  SUBCATEGORY_NAME_IS_EMPTY
} from './constants';
import { useTranslation } from 'react-i18next';
import { deleteMinicategory, saveMinicategory, updateMinicategory } from '../../../redux/minicategory/action';

const CategoryDetail = () => {
  const { t: translate } = useTranslation();
  const { catId } = useParams();
  const dispatch = useDispatch();
  const [openSubcategoryModal, setOpenSubcategoryModal] = useState(false);
  const [openMinicategoryModal, setOpenMinicategoryModal] = useState(false);
  const { allCategory, subcategories } = useSelector((state) => state.categories);
  const { minicategories } = useSelector((state) => state.subcategories);
  const [currentCategory, setCurrentCategory] = useState({});
  const [currentSubcategory, setCurrentSubcategory] = useState({});
  const [subcategoryName, setSubcategoryName] = useState('');
  const [subcategoryDescription, setSubcategoryDescription] = useState('');
  const [minicategoryName, setMinicategoryName] = useState('');
  const [minicategoryDescription, setMinicategoryDescription] = useState('');
  const toggleSubcategoryModal = () => setOpenSubcategoryModal(!openSubcategoryModal);
  const toggleMinicategoryModal = () => setOpenMinicategoryModal(!openMinicategoryModal);
  const categoryId = Number(catId);

  useEffect(() => {
    if (_.isEmpty(allCategory)) {
      dispatch(getAllCategory());
    }

    if (_.isEmpty(subcategories)) {
      dispatch(getSubcategoriesForCategory(categoryId));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(allCategory)) {
      const cat = allCategory.find((cat) => cat.categoryId === categoryId);
      setCurrentCategory(cat);
    }
  }, [allCategory]);

  const handleSaveSubcategory = () => {
    if (subcategoryName === '') {
      return toast.error(translate(SUBCATEGORY_NAME_IS_EMPTY));
    }

    const data = {
      category: categoryId,
      name: subcategoryName,
      description: subcategoryDescription
    };

    dispatch(saveSubcategory(data));
    toggleSubcategoryModal();
    setSubcategoryName('');
  };

  const handleSaveMinicategory = () => {
    if (minicategoryName === '') {
      return toast.error(translate(MINICATEGORY_NAME_IS_EMPTY));
    }

    const data = {
      category: categoryId,
      subcategory: currentSubcategory.subcategoryId,
      name: minicategoryName,
      description: subcategoryDescription
    };

    dispatch(saveMinicategory(data));
    toggleMinicategoryModal();
    setMinicategoryName('');
  };

  const handleSubcategoryRowClick = (subcatId) => {
    const subcategory = subcategories.find(({ subcategoryId }) => subcategoryId === subcatId);
    setCurrentSubcategory(subcategory);
    dispatch(getMinicategoriesForSubcategory(subcategory.subcategoryId));
  };

  const handleUpdateSubcategory = (id, data) => {
    if (isEmpty(data)) return;

    dispatch(updateSubcategory(id, catId, data));
  };

  const handleUpdateMinicategory = (id, data) => {
    if (isEmpty(data)) return;

    dispatch(updateMinicategory(id, currentSubcategory.subcategoryId, data));
  };

  const handleDeleteSubcategory = (subcatId) => {
    const subcategory = subcategories.find((subcat) => subcat.subcategoryId === subcatId);

    Alerts(
      `${translate(DO_YOU_WANT_TO_DELETE)} "${subcategory.name}"?`,
      null,
      'warning',
      {
        confirmButtonText: translate(YES),
        denyButtonText: translate(NO),
        showConfirmButton: true,
        showDenyButton: true
      },
      null,
      () => dispatch(deleteSubcategory(categoryId, subcatId))
    );
  };

  const handleDeleteMinicategory = (minicatId) => {
    const minicategory = minicategories.find((minicat) => minicat.minicategoryId === minicatId);

    Alerts(
      `${translate(DO_YOU_WANT_TO_DELETE)} "${minicategory.name}"?`,
      null,
      'warning',
      {
        confirmButtonText: translate(YES),
        denyButtonText: translate(NO),
        showConfirmButton: true,
        showDenyButton: true
      },
      null,
      () => dispatch(deleteMinicategory(currentSubcategory.subcategoryId, minicatId))
    );
  };

  const DescriptionAndImage = ({ data, show = false }) => {
    return show ? (
      <CardBody>
        <h5>{`${data.name} ${translate(Description)}`}</h5>
        <span>{data.description || `${ADD_SOME_DESCRIPTION_FOR} "${data.name}"`}</span>
        {/* <hr />
                <h5>{'Images'}</h5>
                <Row>
                  <Media body className="img-100" src={imageExample} />
                  <Media body className="img-100" src={imageExample} />
                  <Media body className="img-100" src={imageExample} />
                </Row> */}
      </CardBody>
    ) : null;
  };
  // { type: isError ? 'error' : 'success' });

  const columnsSubcategory = [
    {
      name: translate(Name),
      selector: (row) => decode(row.name),
      sortable: true
    },
    {
      name: translate(Status),
      cell: () => (
        <Button color="success" size="xs">
          {Active}
        </Button>
      )
    },
    {
      name: 'Featured',
      selector: (row) => row.showInStore,
      cell: (row) => (
        <div className="checkbox checkbox-primary" onClick={() => handleUpdateSubcategory(row.subcategoryId, { showInStore: !row.showInStore })}>
          <Input id="checkbox-primary-1" type="checkbox" size={8} checked={row.showInStore} onChange={() => {}} />
          <Label for="checkbox-primary">{}</Label>
        </div>
      )
    },
    {
      name: translate(Action),
      selector: (row) => row.action,
      cell: (row) => <i className="icofont icofont-ui-delete" onClick={() => handleDeleteSubcategory(row.subcategoryId)} />
    }
  ];

  const columnsMinicategory = [
    {
      name: translate(Name),
      selector: (row) => decode(row.name),
      sortable: true
    },
    {
      name: translate(Status),
      cell: () => (
        <Button color="success" size="xs">
          {Active}
        </Button>
      )
    },
    {
      name: 'Featured',
      selector: (row) => row.showInStore,
      cell: (row) => (
        <div className="checkbox checkbox-primary" onClick={() => handleUpdateMinicategory(row.minicategoryId, { showInStore: !row.showInStore })}>
          <Input id="checkbox-primary-1" type="checkbox" size={8} checked={row.showInStore} onChange={() => {}} />
          <Label for="checkbox-primary">{}</Label>
        </div>
      )
    },
    {
      name: translate(Action),
      selector: (row) => row.action,
      cell: (row) => <i className="icofont icofont-ui-delete" onClick={() => handleDeleteMinicategory(row.minicategoryId)} />
    }
  ];

  return (
    <Fragment>
      <Breadcrumb parent="category" title={`${currentCategory.name}`} />
      <Container fluid={true}>
        <CategoryModal
          title={AddSubcategory}
          show={openSubcategoryModal}
          toggle={() => toggleSubcategoryModal()}
          onSave={() => handleSaveSubcategory()}
          placeholder={translate(SUBCATEGORY_NAME)}
          categoryName={subcategoryName}
          categoryDescription={subcategoryDescription}
          handleCategoryName={setSubcategoryName}
          handleCategoryDescription={setSubcategoryDescription}
        />
        <CategoryModal
          title={AddMinicategory}
          show={openMinicategoryModal}
          toggle={() => toggleMinicategoryModal()}
          onSave={() => handleSaveMinicategory()}
          placeholder={translate(MINICATEGORY_NAME)}
          categoryName={minicategoryName}
          categoryDescription={minicategoryDescription}
          handleCategoryName={setMinicategoryName}
          handleCategoryDescription={setMinicategoryDescription}
        />
        <Row>
          <Col sm="6">
            <Card>
              <DescriptionAndImage data={currentCategory} show={true} />
            </Card>
            <hr />
            <Card>
              <CardBody>
                <h5>
                  {`${translate(SUBCATEGORIES)}`}{' '}
                  <Button color="primary" size="xs" onClick={() => toggleSubcategoryModal()}>
                    {`${translate(AddSubcategory)} `} <i className="icofont icofont-plus-square"></i>
                  </Button>
                </h5>
                <CustomDataTables columns={columnsSubcategory} data={subcategories} onRowClick={(row) => handleSubcategoryRowClick(row.subcategoryId)} />
              </CardBody>
            </Card>
          </Col>
          <Col sm="6">
            <Card>
              <DescriptionAndImage data={currentSubcategory} show={!_.isEmpty(currentSubcategory)} />
            </Card>
            <hr style={{ display: !_.isEmpty(currentSubcategory) ? 'block' : 'none' }} />
            <Card>
              {!_.isEmpty(currentSubcategory) ? (
                <CardBody>
                  <h5>
                    {`${currentSubcategory.name} - ${translate(MINICATEGORIES)}`}{' '}
                    <Button color="primary" size="xs" onClick={() => toggleMinicategoryModal()}>
                      {`${translate(AddMinicategory)} `} <i className="icofont icofont-plus-square"></i>
                    </Button>
                  </h5>
                  <CustomDataTables columns={columnsMinicategory} data={minicategories} />
                </CardBody>
              ) : (
                <CardBody>
                  <i className="icofont icofont-info-circle"></i> <span>{`${CLICK_TO_SHOW_MINICATEGORIES}`}</span>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CategoryDetail;
