import { DELETE_MINICATEGORY, SAVE_MINICATEGORY, SET_MINICATEGORY, UPDATE_MINICATEGORY } from '../actionTypes';

export const saveMinicategory = (data) => {
  return {
    type: SAVE_MINICATEGORY,
    payload: data
  };
};

export const updateMinicategory = (id, subcatId, data) => ({
  type: UPDATE_MINICATEGORY,
  payload: { id, subcatId, data }
});

export const setMinicategory = (payload) => {
  return {
    type: SET_MINICATEGORY,
    payload
  };
};

export const deleteMinicategory = (subcatId, minicatId, callback) => {
  return {
    type: DELETE_MINICATEGORY,
    payload: { subcatId, minicatId },
    callback
  };
};
