import { takeLatest } from 'redux-saga/effects';
import customToast from '../../components/alert/CustomToast';
import { DELETED_SUCCESSFULLY, SAVED_SUCCESSFULLY, UPDATED_SUCCESSFULLY } from '../../constant';
import { deleteApi, postApi, putApi } from '../../services';
import { requestHandler } from '../../services/handler';
import { DELETE_MINICATEGORY, SAVE_MINICATEGORY, UPDATE_MINICATEGORY } from '../actionTypes';
import { getMinicategoriesForSubcategory } from '../subcategory/action';

const INVENTORY_URL = process.env.REACT_APP_INVENTORY_URL;

function* postMinincategory(action) {
  const { payload } = action;

  const cb = () => {
    customToast(`${payload.name} ${SAVED_SUCCESSFULLY}`, 'success');
    return getMinicategoriesForSubcategory(payload.subcategory);
  };

  const handleError = (err) => {
    const { errors } = err;
    return errors.map((data) => customToast(data.message, 'error'));
  };

  yield requestHandler(postApi.bind(this, `${INVENTORY_URL}/minicategory`, payload), cb, null, null, handleError);
}

function* updateMinicategory(action) {
  const {
    payload: { id, subcatId, data }
  } = action;

  const cb = () => {
    return getMinicategoriesForSubcategory(subcatId);
  };

  const onSuccess = () => {
    return customToast(UPDATED_SUCCESSFULLY, 'success');
  };

  const onError = (err) => {
    const { errors } = err;
    return errors.map((message) => customToast(message, 'error'));
  };

  yield requestHandler(putApi.bind(this, `${INVENTORY_URL}/minicategory/${id}`, data), cb, onSuccess, null, onError);
}

function* deleteMinincategory(action) {
  const { payload } = action;

  const cb = () => {
    return getMinicategoriesForSubcategory(payload.subcatId);
  };

  const onSuccess = () => {
    return customToast(DELETED_SUCCESSFULLY, 'success');
  };

  const onError = (err) => {
    const { errors } = err;
    return errors.map((message) => customToast(message, 'error'));
  };

  yield requestHandler(deleteApi.bind(this, `${INVENTORY_URL}/minicategory/${payload.minicatId}`), cb, onSuccess, null, onError);
}

export function* WatcherMinicategoryApp() {
  yield takeLatest(SAVE_MINICATEGORY, postMinincategory);
  yield takeLatest(DELETE_MINICATEGORY, deleteMinincategory);
  yield takeLatest(UPDATE_MINICATEGORY, updateMinicategory);
}
