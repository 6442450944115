import { takeLatest } from 'redux-saga/effects';
import customToast from '../../components/alert/CustomToast';
import { DELETED_SUCCESSFULLY, SAVED_SUCCESSFULLY, UPDATED_SUCCESSFULLY } from '../../constant';
import { deleteApi, getApi, postApi, putApi } from '../../services';
import { requestHandler } from '../../services/handler';
import { DELETE_SUBCATEGORY, GET_MINICATEGORY_FOR_SUBCATEGORY, SAVE_SUBCATEGORY, UPDATE_SUBCATEGORY } from '../actionTypes';
import { getSubcategoriesForCategory } from '../category/action';
import { setMinicategoriesForSubcategory } from './action';

const INVENTORY_URL = process.env.REACT_APP_INVENTORY_URL;

function* saveSubcategory(action) {
  const { payload } = action;
  const cb = () => {
    customToast(`${payload.name} ${SAVED_SUCCESSFULLY}`, 'success');
    return getSubcategoriesForCategory(payload.category);
  };

  yield requestHandler(postApi.bind(this, `${INVENTORY_URL}/subcategory`, payload), cb, null, null);
}

function* updateSubcategory(action) {
  const {
    payload: { id, categoryId, data }
  } = action;

  const cb = () => {
    return getSubcategoriesForCategory(categoryId);
  };

  const onSuccess = () => {
    return customToast(UPDATED_SUCCESSFULLY, 'success');
  };

  const onError = (err) => {
    const { errors } = err;
    return errors.map((message) => customToast(message, 'error'));
  };

  yield requestHandler(putApi.bind(this, `${INVENTORY_URL}/subcategory/${id}`, data), cb, onSuccess, null, onError);
}

function* deleteSubcategory(action) {
  const { payload } = action;

  const cb = () => {
    return getSubcategoriesForCategory(payload.catId);
  };

  const onSuccess = () => {
    return customToast(DELETED_SUCCESSFULLY, 'success');
  };

  const onError = (err) => {
    const { errors } = err;
    return errors.map((message) => customToast(message, 'error'));
  };

  yield requestHandler(deleteApi.bind(this, `${INVENTORY_URL}/subcategory/${payload.subcatId}`), cb, onSuccess, null, onError);
}

function* getMinicategoryForSubCategory(action) {
  const { payload: subcategoryId } = action;
  yield requestHandler(getApi.bind(this, `${INVENTORY_URL}/subcategory/${subcategoryId}/minicategories`), setMinicategoriesForSubcategory, null, null);
}

export function* WatcherSubcategoryApp() {
  yield takeLatest(SAVE_SUBCATEGORY, saveSubcategory);
  yield takeLatest(DELETE_SUBCATEGORY, deleteSubcategory);
  yield takeLatest(GET_MINICATEGORY_FOR_SUBCATEGORY, getMinicategoryForSubCategory);
  yield takeLatest(UPDATE_SUBCATEGORY, updateSubcategory);
}
