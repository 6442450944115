import {
  DELETE_SUBCATEGORY,
  GET_MINICATEGORY_FOR_SUBCATEGORY,
  RESET_MINICATEGORY_FOR_SUBCATEGORY,
  SAVE_SUBCATEGORY,
  SET_MINICATEGORY_FOR_SUBCATEGORY,
  SET_SUBCATEGORY,
  UPDATE_SUBCATEGORY
} from '../actionTypes';

export const saveSubcategory = (data) => {
  return {
    type: SAVE_SUBCATEGORY,
    payload: data
  };
};

export const setSubcategory = (payload) => {
  return {
    type: SET_SUBCATEGORY,
    payload
  };
};

export const updateSubcategory = (id, categoryId, data) => ({
  type: UPDATE_SUBCATEGORY,
  payload: { id, categoryId, data }
});

export const deleteSubcategory = (catId, subcatId, callback) => {
  return {
    type: DELETE_SUBCATEGORY,
    payload: { catId, subcatId },
    callback
  };
};

export const getMinicategoriesForSubcategory = (subcategory) => {
  return {
    type: GET_MINICATEGORY_FOR_SUBCATEGORY,
    payload: subcategory
  };
};

export const setMinicategoriesForSubcategory = (payload) => {
  return {
    type: SET_MINICATEGORY_FOR_SUBCATEGORY,
    payload
  };
};

export const resetMinicategoriesForSubcategory = () => {
  return {
    type: RESET_MINICATEGORY_FOR_SUBCATEGORY
  };
};
