import {
  DELETE_CATEGORY,
  GET_CATEGORY,
  GET_SUBCATEGORY_FOR_CATEGORY,
  RESET_SUBCATEGORY_FOR_CATEGORY,
  SAVE_CATEGORY,
  SET_CATEGORY,
  SET_SUBCATEGORY_FOR_CATEGORY,
  UPDATE_CATEGORY
} from '../actionTypes';

export const saveCategory = (data) => {
  return {
    type: SAVE_CATEGORY,
    payload: data
  };
};

export const setCategory = (payload) => {
  return {
    type: SET_CATEGORY,
    payload
  };
};

export const getAllCategory = () => {
  return {
    type: GET_CATEGORY
  };
};

export const updateCategory = (id, data) => ({
  type: UPDATE_CATEGORY,
  payload: { id, data }
});

export const deleteCategory = (payload, callback) => {
  return {
    type: DELETE_CATEGORY,
    payload,
    callback
  };
};

export const getSubcategoriesForCategory = (catId) => {
  return {
    type: GET_SUBCATEGORY_FOR_CATEGORY,
    payload: catId
  };
};

export const setSubcategoriesForCategory = (payload) => {
  return {
    type: SET_SUBCATEGORY_FOR_CATEGORY,
    payload
  };
};

export const resetSubcategoriesForCategory = () => {
  return {
    type: RESET_SUBCATEGORY_FOR_CATEGORY
  };
};
